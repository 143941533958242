<template>
    <div>
    <v-card class="elevation-12" style="position:absolute;top:50%;left:50%;transform:translate(-50%, -60%);" width="240">
		<v-toolbar color="primary" dark flat dense>
			<v-toolbar-title>登录</v-toolbar-title>
		</v-toolbar>
        <v-card-text>
            <v-form ref="loginform">
                <v-text-field label="用户名" v-model="username" :rules="textRules.concat(usernameRules)"/>
                <v-text-field label="密码" type="password" v-model="password" :rules="textRules"/>
            </v-form>
        </v-card-text>
        <v-card-actions class="pb-5">
            <v-spacer/>
                <v-btn color="primary" :loading="loading" :disabled="loading" @click.stop="onClickLogin">登录</v-btn>
            <v-spacer/>
        </v-card-actions>
    </v-card>
    <v-snackbar color="error" v-model="snackbar">{{errmsg}}</v-snackbar>
    </div>
</template>

<script>
    import store from '../store.js'
    export default {
        data() {
            return {
                username: '',
                password: '',
                loading: false,
                snackbar: false,
                errmsg: '',
				usernameRules: [v => (/^[0-9a-zA-Z]*$/g).test(v) || '只允许字母和数字', v => v.length >= 4 || '至少4个字符'],
				textRules: [v => !!v || '此项为必填'],
            }
        },
        methods: {
            onClickLogin() {
				if (!this.$refs.loginform.validate()) return;
                this.loading = true;
                this.$login(this.username, this.password).then(res => {
                    if (res.success) {
                        //this.watchCount();
                        this.$router.push('/');
                    } else {
                        this.errmsg = res.errmsg;
                        this.snackbar = true;
                    }
                }).finally(()=>{
                    this.loading = false;
                });
            },
            /*
			watchCount() {
                const db = this.$tcbapp.database();
                const _ = db.command;
				let filter;
				const u = this.$getCurrentUser();
				if (this.$hasPrivilege('查看全部任务')) {
					filter = {};
				} else if (this.$hasPrivilege('健康顾问')) {
					filter = {'consultantId':u.consultantId, targetRole:'健康顾问'};
				} else {
                    filter = {targetRole: _.in(u.role), region: _.in(u.region)};
                }
                filter.closed = _.neq(true);
                this.$watchNote(filter);
			}
            */
        }
    }
</script>